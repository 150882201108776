import { Box, Div, Img, Text, Title } from 'components'
import { RequestDemo, TryOrSchedule } from 'containers/en'
import ComfortCottage from "images/ComfortCottage8bitcopy.png"
import Layout from 'layouts/en'
import React from 'react'
import meetDesktop from '../images/meet-desktop-en.png'

const StartOrDemo = ({}) => {
  return (
    <Div position={'relative'}>
      <Div display={['none','block']} position={'absolute'} top={180} right={65}>
        <Img shadow src={ComfortCottage} width={335} alt={''} />
      </Div>
      <Div width={['100%',776]}>
        <Div px={[0]} pt={[7,8]}>
          <Title mb={[4,5]} fontSize={[7,8]}>Take a left turn today</Title>
          <Text mb={[7,8]} fontSize={[5,8]}>People on Stairlin have more time
            for their family and leisure</Text>
        </Div>
        <Div display={'flex'}>
          <Div width={['100%',314]} mr={6} display={['none','block']}>
            <Title mb={6} fontSize={[5]}>Start Now</Title>
            <ul style={{ paddingLeft: 0, listStyle: 'none' }}>
              <li><Text fontSize={3} mb={3}>• Easy setup</Text></li>
              <li><Text fontSize={3} mb={3}>• Free for starters</Text></li>
              <li><Text fontSize={3} mb={3}>• No credit card required</Text></li>
              <li><Text fontSize={3} mb={3}>• Cancel any time</Text></li>
            </ul>
          </Div>
          <Div mx={[0, 6]} width={['100%',314]}>
            <RequestDemo />
          </Div>
        </Div>
      </Div>
    </Div>
  )
}

export default () => {
  return (
    <Layout p={5}>
      <Div pt={[6,8]} px={[0,6]} textAlign={['left']}>
        <Title mb={5} fontSize={[7,9]}>Virtual Visit</Title>
        <Text tag={'h2'} mb={7} fontSize={[6,8]}>Stairlin is happy to offer you now its new video application, for your virtual appointments. So you can connect with your customers wherever they are.</Text>

        <Div mb={[60, 80]} flex={1}>
          <Box bg={'white'} m={0} p={[4,4]}>
            <img width={['100%']} src={meetDesktop} alt=""/>
          </Box>
        </Div>

        <Text mb={5} fontSize={[3,5]} textAlign={'justify'}>We empathise deeply with all therapists and other professionals,  who have traditionally provided their services in a confidential, safe, and face-to-face environment. We are now significantly affected by the pandemic. Therapists are increasingly reaching towards video solutions as an alternative.</Text>
        <Text mb={5} fontSize={[3,5]} fontWeight={700} textAlign={'justify'}>But how to choose a reliable and secure solution that ensures patient confidentiality amongst the numerous options?</Text>
        <Text mb={5} fontSize={[3,5]} textAlign={'justify'}>Stairlin has set itself the mission to provide you with a secure tool so you can continue helping your patients from the safety of your home. Our video solution for your virtual appointments is:</Text>

        <ul style={{ paddingLeft: 20, listStyle: 'none' }}>
          <li><Text mb={[1,2]} fontSize={[3,5]}>• Swiss-made & 100% secure</Text></li>
          <li><Text mb={[1,2]} fontSize={[3,5]}>• Compliant with data protection (GDPR) and health (HIPAA) policies</Text></li>
          <li><Text mb={[1,2]} fontSize={[3,5]}>• Generated by a unique and secured link for every appointment</Text></li>
        </ul>

        <Div mt={[7,8]}>
          <TryOrSchedule />
        </Div>

        <Div mt={[7,180]}>
          <Text tag={'h2'} mb={5} fontSize={[6,8]} fontWeight={700}>What is Stairlin?</Text>
          <Text mb={5} fontSize={[3,5]} textAlign={'justify'}>Stairlin is an all-in-one solution helping you with your:</Text>
          <ul style={{ paddingLeft: 20, listStyle: 'none' }}>
            <li><Text mb={[1,2]} fontSize={[3,5]}>• Accounting</Text></li>
            <li><Text mb={[1,2]} fontSize={[3,5]}>• Billing</Text></li>
            <li><Text mb={[1,2]} fontSize={[3,5]}>• Schedule booking</Text></li>
            <li><Text mb={[1,2]} fontSize={[3,5]}>• Customer interactions</Text></li>
          </ul>
          <Text mt={60} mb={5} fontSize={[3,5]} textAlign={'justify'}>We integrate all your communication channels and admin tasks in one simple and seamless interface so you can:</Text>
          <ul style={{ paddingLeft: 20, listStyle: 'none' }}>
            <li><Text mb={[1,2]} fontSize={[3,5]}>• Pick up all your client conversations from different channels (What’sapp, Facebook etc..) in one location</Text></li>
            <li><Text mb={[1,2]} fontSize={[3,5]}>• Communicate freely with your patients in a confidential setting</Text></li>
            <li><Text mb={[1,2]} fontSize={[3,5]}>• Manage your schedule, billing and accounting with a few clicks</Text></li>
            <li><Text mb={[1,2]} fontSize={[3,5]}>• Focus more time on doing what you love, helping others</Text></li>
          </ul>
          <Text mt={60} mb={5} fontSize={[3,5]} textAlign={'justify'}>Let Stairlin do the grunt work so you can stay connected with your customers.</Text>
        </Div>

        <Div mt={[7,8]}>
          <StartOrDemo />
        </Div>
      </Div>
    </Layout>
  )
}
